import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { FirstinitialsPipe } from './firstinitials.pipe';
import { DategrouperPipe } from './dategrouper.pipe';
import { DateAgoPipe } from './date-ago.pipe';

@NgModule({
declarations: [FirstinitialsPipe, DategrouperPipe, DateAgoPipe],
imports: [CommonModule],
exports: [FirstinitialsPipe, DategrouperPipe, DateAgoPipe],
})

export class PipesModule {}
