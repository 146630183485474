import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstinitials'
})
export class FirstinitialsPipe implements PipeTransform {

  transform(fullName: string): any {
    return fullName
      .split(' ')
      .map(n => n[0])[0].toUpperCase();
      // .join('');
  }

}
